.category-tooltip {
}

.category-tooltip .tooltip-inner {
    max-width: 450px !important;
}

.flex-row label {
    padding-left: 5px;
}

.category-message {
    padding-right: 5px;
}