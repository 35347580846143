.report-header {
    display: flex;
    flex-flow: column;
}

.report-header-text-area-container {
    display: flex;
    flex-flow: column;
    margin-top: auto;
    height: 400px;
    width: 1000px;
}

.report-header > .text-area-container {
    width: 1000px;
    height: 400px;
}

.report-header.required .report-question:before {
    color: red;
    content: "*";
    position: absolute;
    margin-left: -10px;
}

.report-note:before {
    color: red;
    content: "*";
    position: absolute;
    margin-left: -10px;
}

.drag-and-drop {
    display: inline-block;
    width: 300px;
    height: 200px;
    position: relative;
    cursor: pointer;
    margin-right: 25px;
}

.drag-and-drop-border {
    border: solid grey 4px;
    background-color: rgba(255,255,255,.8);
    position: absolute;
    padding: 10px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
}

.drag-and-drop-text {
    font-size: 16px;
    height: 100%;
}

.active-border {
    border: dashed grey 4px !important;
    background-color: rgba(255,255,255,.8) !important;
}

.no-pointer { 
    cursor: default;
}

.attachment-icon {
    margin-left: 5px;
    cursor: pointer;
}

.btn-outline-secondary {
    color: var(--energera-darkgrey);
    border-color: var(--energera-darkgrey);
}

.btn-outline-secondary:hover {
    background-color: var(--energera-darkgrey);
    border-color: var(--energera-darkgrey);
}
