.main-blob {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0 10%;
    width: 80%;
}

.main-blob > section { 
    font-size: 1.25em;
    padding: 15px 0;
}

.form-signin {
    width: 100%;
    max-width: 500px;
    padding: 15px;
    margin: auto;
}

h1{
    text-align: center;
}

.form-signin .checkbox {
    font-weight: 400;
}

.form-signin .form-floating:focus-within {
    z-index: 2;
}

.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.alert-success {
    color: white;
    background-color: rgb(41, 60, 23);
    border-color: rgb(60, 91, 35);
}
