.new-case-container {
    display: flex;
    flex-flow: column;
    height: 94vh;
    margin: 0 25px;
}

.new-case-container .new-case-info-body {
    flex: 1 1 auto;
}

.new-case-container .new-case-footer {
    flex: 0 1 150px;
}