.no-content-container {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: black solid 1px;
}

.text-container {
    margin-right: 20px;
}

.icon-container {
    /*flex: 1 1 0;*/
}