:root {
    --energera-green: rgba(79, 205, 134, 1);
    --energera-blue: rgba(30, 84, 128, 1);
    --energera-yellow: rgba(237, 167, 0, 1);
    --energera-white: rgba(242, 244, 249, 1);
    --energera-grey: rgba(183, 196, 212, 1);
    --energera-darkgrey: rgba(108, 117, 131, 1);
    --energera-black: rgba(28, 30, 38, 1);
}

/* Fonts */

@font-face {
    font-family: 'Aeonik-Bold';
    src: local('Aeonik-Bold'), url(./Fonts/Aeonik-Bold.woff) format('woff');
}
@font-face {
    font-family: 'Aeonik-BoldItalic';
    src: local('Aeonik-BoldItalic'), url(./Fonts/Aeonik-BoldItalic.woff) format('woff');
}
@font-face {
    font-family: 'Aeonik-Light';
    src: local('Aeonik-Light'), url(./Fonts/Aeonik-Light.woff) format('woff');
}
@font-face {
    font-family: 'Aeonik-LightItalic';
    src: local('Aeonik-LightItalic'), url(./Fonts/Aeonik-LightItalic.woff) format('woff');
}
@font-face {
    font-family: 'Aeonik-Regular';
    src: local('Aeonik-Regular'), url(./Fonts/Aeonik-Regular.woff) format('woff');
}
@font-face {
    font-family: 'Aeonik-RegularItalic';
    src: local('Aeonik-RegularItalic'), url(./Fonts/Aeonik-RegularItalic.woff) format('woff');
}

html,
body {
    height: 100%;
    margin: 0;
    background-color: var(--energera-white);
    font-family: 'Aeonik-Regular', serif;
}

.flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.ofe {
    overflow: hidden;
    text-overflow: ellipsis;
}

.btn-ml-25 {
    margin-left: 25px;
}

.attachment-container {
    flex: 1 1 0;
    padding: 0 8px;
}

.attachment-container span {
    overflow: hidden;
    white-space: nowrap;
}

.text-area-container {
    display: flex;
    flex-flow: column;
    flex: 1 1 0;
}

.text-area-blob:before {
    color: black;
    content: "*";
    position: absolute;
    margin-left: -10px;
}

/* Pagination */

.pagination-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: var(--energera-darkgrey);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    width: 100%;
    padding: 5px 0;
}

.active-page {
    background-color: var(--energera-yellow) !important;
}

.pagination-group {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
}

.pagination-element {
    text-align: center;
    font-weight: bold;
    font-size: 1em;
    box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
    height: 30px;
    width: 30px;
    color: white;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.pagination-element:hover {
    cursor: pointer;
    color: white;
    background-color: var(--energera-yellow);
}