.no-cases-container {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    height: 90vh;
    /*border: black solid 1px;*/
}

.text-container {
    /*margin-right: 20px;*/
}

.icon-container {
    /*flex: 1 1 0;*/
}