.error-container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 56px);
}

.error-content {
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.error-title {
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 150px;
}

@media (max-width: 768px) {
    .error-title {
        font-size: 75px;
    }
}

.error-description {
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 150px;
}

@media (max-width: 768px) {
    .error-description {
        font-size: 15px;
        margin-bottom: 60px;
    }
}

.error-button {
    display: table;
    max-width: 531px;
    width: 100%;
    min-height: 69px;
    background: #F1AD1E;
    border-radius: 5px;
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    padding-left: 60px;
    padding-right: 60px;
    color: #202227;
    transition: 250ms;
}

@media (max-width: 768px) {
    .error-button {
        max-width: 300px;
        min-height: 35px;
        font-size: 15px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.error-button:visited {
    color: #202227;
}

.error-button:hover {
    background: #5C5E63;
    text-decoration: none;
    color: #FFBB00;
    transition: 100ms;
}

.error-button-text {
    display: table-cell;
    vertical-align: middle;
}