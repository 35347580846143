.timeline {
    margin: 50px 60px;
    height: 4px;
    background-color: #ccc;
}

.timeline-progress {
    width: 0;
    height: 100%;
    background-color: var(--energera-darkgrey);
}

.timeline-items {
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -12px;
    display: flex;
    justify-content: space-between;
}

.timeline-item {
    position: relative;
}

.timeline-item::before {
    content: '';
    width: 20px;
    height: 20px;
    background-color: #ccc;
    display: block;
    border-radius: 100%;
}

.timeline-content {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ddd;
    width: 100px;
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
}

.timeline-content::before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: #ddd;
    display: block;
    position: absolute;
    top: -8px;
    margin-left: -2px;
    left: 50%;
    transform: rotate(-45deg) translateX(-50%);
}

.timeline-item.active::before {
    background-color: var(--energera-yellow);
}

.timeline-item.active .timeline-content {
    color: var(--energera-white);
    background-color: var(--energera-yellow);
}

.timeline-item.active .timeline-content::before {
    background-color: var(--energera-yellow);
}