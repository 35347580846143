/* Extend bootstrap classes */

.list-group {
    overflow-y: scroll;
    max-height: 86vh;
}

.list-group-item {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

/* Extend bootstrap switch group button*/
.switch-group > .btn-primary {
    background-color: var(--energera-green);
    border-color: var(--energera-green);
    color: var(--energera-white)
}

.switch-group > .btn-light {
    background-color: var(--energera-blue);
    border-color: var(--energera-blue);
    color: var(--energera-white)
}

/* List group */

.case-toggle {
    height: 4vh;
    width: 100%;
}

/* Case sidebar */
.case-item {
    background-color: var(--energera-grey);
    color: var(--energera-black);
    cursor: pointer;
}

.case-item-active {
    background-color: var(--energera-blue);
    color: var(--energera-white);
}

.case-item:hover {
    background-color: var(--energera-blue);
    color: var(--energera-white)
}

/* Date filter */

.date-filter-container {
    display: flex;
    flex-flow: row;
    align-items: center;
    width: 100%;
    height: 4vh;
}

.date-filter-date-container {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    width: 95%;
}

.date-filter-icon {
    cursor: pointer;
    margin-left: auto;
}

.date-filter-date {
    flex: 1 1 0;
    text-align: center;
    border: black solid 1px;
    margin: 0 4px;
}

/* Case card*/

.case-id {
    font-weight: bold;
    font-size: 20px;
}

.case-report {
    height: 50px;
}

/* Case options*/

.case-options > * {
    flex: 1 1 0;
    margin: 0 5px;
}

.case-options > div > .btn {
    background-color: var(--energera-blue);
    border-color: var(--energera-blue);
}

.btn-check:active+.btn-secondary, .btn-check:checked+.btn-secondary, .btn-secondary.active, .btn-secondary:active, .show>.btn-secondary.dropdown-toggle {
    background-color: var(--energera-green);    
    border-color: var(--energera-green);
}

/* Case review */

#case-review-container {
    display: flex;
    flex-flow: column;
    margin: 0 10px;
    height: 94vh;
}

#case-review-header {

}

#case-review-body {
    flex: 1 1 0;
}

/* Cast attachment */

.case-attachment-table-container {

}

.case-attachment-embedded-container {
    display: flex;
    flex: 1 1 0;
    justify-content: center;
}

.selected-attachment {
    background-color: var(--energera-grey);
}

.selected-attachment-file:hover {
    background-color: var(--energera-grey);
    cursor: pointer;
}

.embedded-attachment {

}

.embedded-video {

}


/* Case forum */

.case-forum-container {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.case-forum-container .case-forum-comments-container {
    height: 63vh;
    padding: 10px;
    overflow-y: scroll;
    background-color: white;
}

.case-forum-container textarea {
    border: none;
    resize: none;
}

.case-forum-container textarea::placeholder {
    color: var(--energera-darkgrey);
}

.case-forum-container label {
    height: 0;
    width: 0;
    visibility: hidden;
}

.case-forum-container button {
    cursor: pointer;
}

.case-forum-container button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.case-forum-container button[type="submit"] {
    background-color: var(--energera-green);
    color: var(--energera-white);
    border-radius: 4px;
}

.case-forum-modal-container {
    display: flex;
    flex-flow: column;
    margin: 10% 25%;
    max-width: 100%;
    max-height: 100%;
    background-color: var(--energera-grey);
    z-index: 999;
}

.modal-header {
    display: flex;
    flex-flow: row;
    border-bottom: black solid 1px;
}

.modal-body {
    display: flex;
    justify-content: center;
    overflow-y: scroll;
    max-height: 80vh;
    max-width: 80vw;
}

.modal-attachment {
    flex: 1 1 0;
    max-width: 100%;
}

video {
    width: 100%;
    height: auto;
}

.comment-box {
    display: flex;
    flex-wrap: wrap;
    margin: auto 0 1vh 0;
    background: white;
    border-radius: 4px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    padding: 14px;
    transition: min-height 0.4s ease;
    max-height: 46px;
}

.comment-box.modified {
    max-height: unset;
}

.case-forum-container .header {
    transition: opacity 0.4s ease 0.2s;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-40px);
}

.case-forum-container .user {
    display: flex;
    align-items: center;
}

.case-forum-container .user img {
    border-radius: 20px;
    margin-right: 10px;
}

.comment-box.expanded .header {
    transform: translateY(10px);
    opacity: 1;
    visibility: visible;
}

.comment-field {
    background-color: white;
}

.case-forum-container .comment-field {
    color: black;
    transition: transform 0.4s ease;
    font-size: 18px;
    width: 100%;
    outline: none;
    padding: 0;
    min-height: 100px;
    transform: translateY(-32px);
    line-height: 1;
}

.comment-box-footer {
    display: flex;
    flex-flow: row;
    width: 100%;
}

.case-forum-container .comment-box-footer .comment-box-attachments {
    display: flex;
    flex-flow: row;
    align-items: center;
    width: 70%;
}

.case-forum-container .comment-box-footer .comment-box-attachments .comment-box-dropzone {
    cursor: pointer;
    margin-right: 10px;
}

.case-forum-container .comment-box-footer .comment-box-attachments .comment-box-attachments-container {
    display: flex;
    flex-flow: row;
    overflow-x: auto;
    justify-content: space-around;
}

.case-forum-container .comment-box-footer .actions {
    width: 30%;
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    opacity: 0;
    transition: opacity 0.4s ease 0.2s;
}

.comment-box.expanded .comment-box-footer .actions {
    opacity: 1;
}

.case-forum-container .comment-box-footer .actions button {
    margin-left: 6px;
    font-size: 14px;
    padding: 12px;
    border: none;
    line-height: 1;
}

.case-forum-container .comment-box-footer .actions .cancel {
    background: none;
}

.forum-comment-container {
    display: flex;
    flex-flow: column;
    max-width: 45%;
    padding: 10px;
    margin-top: 15px;
    background-color: var(--energera-darkgrey);
    color: var(--energera-white)
}

.forum-comment-header {
    border-bottom: 1px solid grey;
    font-weight: bold;
}

.forum-comment-body {
    display: flex;
    flex-flow: column;
}

.forum-comment-attachments-container {
    display: flex;
    flex-flow: column;
}

.forum-comment-attachments-container span {
    color: var(--energera-blue);
}

.forum-comment-attachment {
    cursor: pointer;
}

.forum-comment-footer {
    text-align: right;
}

#forum-comment-user-id {
    padding-left: 15px;
}

.is-mine {
    margin-left: auto;
    background-color: var(--energera-green);
    color: var(--energera-black);
}

.case-forum-refresh-counter {
    cursor: pointer;
    margin-bottom: 5px;
    width: fit-content;
    font-weight: bold;
}

/* Case status */

.case-current-status-container {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    height: 60%;
    padding-bottom: 20px;
}

.case-status-admin-container {
    display: flex;
    flex-flow: column;
    margin-top: auto;
    height: 400px;
}

.case-card-container {
    min-height: 350px;
    width: 45%;
}

.open-status {
    background-color: var(--energera-green);
    color: var(--energera-black);
}

.closed-status {
    background-color: var(--energera-darkgrey);
    color: var(--energera-white)
}

.case-status-modal-container {
    position: absolute;
    width: 400px;
    height: 250px;
    top: 50%;
    left: 50%;
    margin-top: -125px;
    margin-left: -200px;
    background-color: var(--energera-grey);
    z-index: 999;
}

.case-status-modal-container > * > .modal-question {
    height: 160px;
    font-size: 1.5em;
}

.case-status-modal-container > * > .modal-buttons {
    display: flex;
    flex-flow: row;
    width: 100%;
    justify-content: space-between;
    margin-left: auto;
}

.case-status-modal-container > * > .modal-buttons > * {
    width: 48%;
}

/* Miscellaneous */

.case-subpage-container {
    display: flex;
    flex-flow: column;
    margin: 15px 0;
    height: 80vh;
}

.text-right {
    float: right;
}

.w-100 {
    width: 100%;
}

.text-area-auto {
    border: solid black 1px;
    padding: 5px;
    white-space: pre-wrap;
    background-color: white;
}

.margin-top-bottom {
    margin: 10px 0;
}