.form-signin {
    width: 100%;
    max-width: 500px;
    padding: 15px;
    margin: auto;
}

.form-signin .checkbox {
    font-weight: 400;
}

.form-signin .form-floating:focus-within {
    z-index: 2;
}

.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.alert-danger{
    margin-top: 20px;
    color: white;
    background-color: rgb(56, 22, 22);
    border-color: rgb(89, 35, 43);
}
