.question-container {
    padding-left: 25px;
    padding-top: 10px;
    display: flex;
    flex-flow: column;
}

.question-text {
    font-weight: bold;
    padding-top: 10px;
}

.question-container.required .question-text:before {
    color: red;
    content: "*";
    position: absolute;
    margin-left: -15px;
}

.question-tooltip {
    border: 5px solid black;
}

.question-tooltip .tooltip-inner {
    max-width: 450px !important;
}

.question-container > textarea {
    height: 150px;
}