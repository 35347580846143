body {
    padding-bottom: 20px;
}

.navbar {
    margin-bottom: 20px;
    background-color: var(--energera-darkgrey);
}

.logo {
    width: 225px;
    height: auto;
}

.btn-outline-primary {
    color: var(--energera-blue);
    border-color: var(--energera-blue);
}

.btn-outline-primary:hover {
    border-color: var(--energera-blue);
    background-color: var(--energera-blue);
}

.btn-outline-primary:disabled {
    color: var(--energera-blue);
    border-color: var(--energera-blue);
}

.btn-outline-danger {
    color: var(--energera-yellow);
    border-color: var(--energera-yellow);
}

.btn-outline-danger:hover {
    border-color: var(--energera-yellow);
    background-color: var(--energera-yellow);
}

.btn-outline-delete {
    color: red;
    border-color: red;
}

.btn-outline-delete:hover {
    color: white;
    border-color: red;
    background-color: red;
}