.loading-circle {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    animation: 2s linear infinite loading-circle-animation;
    max-width: 300px;
}

@keyframes loading-circle-animation {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg);
    }
}

.loading-circle>circle {
    animation: 1.4s ease-in-out infinite both circle-animation;
    display: block;
    fill: transparent;
    stroke: var(--energera-green);
    stroke-linecap: round;
    stroke-dasharray: 283;
    stroke-dashoffset: 280;
    stroke-width: 2px;
    transform-origin: 50% 50%;
}

@keyframes circle-animation {
    0%, 25% {
        stroke-dashoffset: 280;
        transform: rotate(0);
    }
    50%, 75% {
        stroke-dashoffset: 75;
        transform: rotate(45deg);
    }
    100% {
        stroke-dashoffset: 280;
        transform: rotate(360deg);
    }
}

.loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 175px;
}
.loading-background{
    position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    background-color: var(--energera-black);
    z-index:9999;

}