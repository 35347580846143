.agreement-container {
    
}

.agreement-checkbox-text {
    padding-left: 5px;
}

.agreement-document {
    cursor: pointer;
    color: blue;
}

.agreement-policy-documents {
    margin-left: 2rem;
}

.agreement-core-values-header {
    color: var(--energera-yellow);
    font-weight: 500;
}

.agreement-core-values-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.agreement-core-value-card {
    flex: 0 0 30%;
    min-width: 300px;
    margin: 10px 0;
}

.agreement-core-value-card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.agreement-core-value-card-header > img {
    width: 75px;
    height: 75px;
}

.agreement-core-value-card-body {
    font-size: 0.85em;
}